/* variables */

/* color - basic */
$basic-black-color1: hsla(0, 0, 28%, 1);
$basic-black-color2: hsla(0, 0, 28%, 1);
$basic-white-color1: hsla(0, 0, 100%, 1);
$basic-gray-color1: hsla(0, 0%, 98%, 1);
$basic-gray-color2: hsla(0, 0%, 88%, 1);
$basic-gray-color3: hsla(0, 0%, 70%, 1);
$basic-gray-color4: hsla(0, 0%, 51%, 1);
$basic-blue-color1: hsla(218, 35%, 94%, 1);

/* color - use */
$use-color-primary1: hsla(232, 64%, 65%, 1);
$use-color-primary2: hsla(221, 30%, 89%, 1);
$use-color-secondary1: hsla(194, 38%, 50%, 1);
$use-color-secondary2: hsla(183, 39%, 80%, 1);
$use-color-background1: hsla(0, 0, 100%, 1);
$use-color-background2: hsla(210, 12%, 94%, 1);
$use-color-error1: hsla(0, 79%, 63%, 1);
$use-color-success1: #198754;
$use-color-body1: $basic-black-color1;
$use-color-body2: $basic-black-color2;
$use-color-adapter1: hsla(195, 52%, 68%, 1);
$use-color-adapter2: hsla(194, 32%, 90%, 1);
$use-color-integration1: hsla(44, 25%, 74%, 1);
$use-color-integration2: hsla(43, 20%, 93%, 1);
$use-color-resource1: hsla(306, 24%, 66%, 1);
$use-color-resource2: hsla(300, 28%, 95%, 1);
$use-color-ftp: hsla(184, 49%, 71%, 1);
$use-color-sftp: hsl(245, 60%, 80%, 1);

/* typo */
$use-eng-font: "Noto Sans KR";
$use-kor-font: "Noto Sans KR";

/* basic size */
$basic-size1: 4.8rem;
$basic-size2: 4rem;
$basic-size3: 3.6rem;
$basic-size4: 3.2rem;
$basic-size5: 2.8rem;
$basic-size6: 2.4rem;
$basic-size7: 2rem;
$basic-size8: 1.6rem;
$basic-size9: 1.4rem;
$basic-size10: 1.3rem;
$basic-size11: 1.2rem;
$basic-size12: 1.8rem;

/* font - use */
$use-font-size-headline1: $basic-size1;
$use-font-size-headline2: $basic-size2;
$use-font-size-headline3: $basic-size4;
$use-font-size-headline4: $basic-size5;
$use-font-size-headline5: $basic-size6;
$use-font-size-headline6: $basic-size7;
$use-font-size-headline7: $basic-size12;
$use-font-size-body1: $basic-size8;
$use-font-size-body2: $basic-size9;
$use-font-size-button1: $basic-size9;
$use-font-size-caption1: $basic-size11;
$use-font-size-outline1: $basic-size10;

/* font-weight */
$use-font-weight-light: 300;
$use-font-weight-regular: 400;
$use-font-weight-medium: 500;
$use-font-weight-bold: 700;

/* line-height */
$use-line-height-min: 1;
$use-line-height-normal: 1.45;
$use-line-height-wide: 1.7;
$use-line-height-max: 2;

/* letter-spacing */
$use-letter-spacing-min: -0.03em;
$use-letter-spacing-normal: 0em;
$use-letter-spacing-wide: 0.03em;
$use-letter-spacing-max: 0.08em;

/* border-radius */
$use-border-radius-xs: 0.5em;
$use-border-radius-sm: 1em;
$use-border-radius-md: 2em;
$use-border-radius-lg: 3em;
$use-border-radius-circle: 50%;

/* mixin */
@mixin clear() {
  clear: both;
  display: table;
  content: "";
}

@mixin inner() {
  max-width: min(130rem, 100%);
  padding: 0 1.2rem;
  margin: 0 auto;
}

@mixin wide-inner() {
  max-width: min(145rem, 100%);
  padding: 0 2.4rem;
  margin: 0 auto;
}

@mixin check-img($size: 7.5, $color: "fff", $ratio: 50%) {
  background-size: #{$ratio} auto;
  background-repeat: no-repeat;
  background-position: calc(50% + 0.1rem) calc(50% + 0.1rem);
  *background-image: url("data:image/svg+xml,%3Csvg width='#{$size}' height='#{$size}' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.629 12c.34 0 .611-.15.803-.452L11.8 1.329c.149-.23.201-.416.201-.602C12 .292 11.72 0 11.284 0c-.315 0-.49.106-.681.417L4.593 10.2l-3.16-4.325c-.202-.275-.394-.39-.682-.39-.437 0-.751.31-.751.753 0 .186.079.39.227.585L3.8 11.53c.245.32.49.47.83.47Z' fill='%23#{$color}'/%3E%3C/svg%3E");
  background-image: url("data:image/svg+xml,%3Csvg width='#{$size}' height='#{$size}' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.62.569a.662.662 0 1 1 .936.936l-3.97 3.97a.662.662 0 0 1-.936 0L.444 3.27a.662.662 0 0 1 .936-.935L3.118 4.07 6.62.57Z' fill='%23#{$color}'/%3E%3C/svg%3E");
}

@mixin arrow-prev-img($size: 10, $color: "fff", $ratio: 50%) {
  background-size: #{$ratio} auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='#{$size}' height='#{$size}' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9.565 12 4.695-4.837a.675.675 0 0 0 .201-.478.7.7 0 0 0-.185-.484.645.645 0 0 0-.462-.201.622.622 0 0 0-.46.204L8.192 11.52A.675.675 0 0 0 8 12a.7.7 0 0 0 .192.48l5.162 5.316a.622.622 0 0 0 .922.003.677.677 0 0 0 .185-.484.7.7 0 0 0-.2-.478l-4.696-4.838Z' fill='%23#{$color}' /%3E%3C/svg%3E");
}

@mixin arrow-next-img($size: 10, $color: "fff", $ratio: 50%) {
  background-size: #{$ratio} auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='#{$size}' height='#{$size}' fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.435 12 9.74 16.838a.676.676 0 0 0-.201.478.7.7 0 0 0 .185.484.644.644 0 0 0 .462.201.622.622 0 0 0 .46-.204l5.162-5.316A.675.675 0 0 0 16 12a.7.7 0 0 0-.192-.48l-5.162-5.316a.645.645 0 0 0-.46-.204.622.622 0 0 0-.462.2.677.677 0 0 0-.185.485.7.7 0 0 0 .2.478l4.696 4.838Z' fill='%23#{$color}'/%3E%3C/svg%3E");
}

@mixin deco-between-list-items(
  $symbols: "|",
  $gap: 0 1rem,
  $size: 0.8em,
  $color: inherit
) {
  display: flex;
  align-items: center;
  &:after {
    display: block;
    font-size: #{$size};
    margin: #{$gap};
    color: #{$color};
    content: "#{$symbols}";
  }
}

@mixin deco-under-text-line(
  $color: $basic-black-color1,
  $gap: 0.3rem,
  $height: 1px,
  $width: 100%
) {
  position: relative;
  &::after {
    display: block;
    width: $width;
    height: $height;
    margin-top: $gap;
    background: $color;
    content: "";
  }
}

@mixin ellipsis($line-cnt, $line-height) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-cnt;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: $line-height;
  max-height: calc(#{$line-height} * #{$line-cnt});
}

p[data-theme="primary"] ,
span[data-theme="primary"] ,
a[data-theme="primary"] {
  color: $use-color-primary1;
}

p[data-theme="secondary"] ,
span[data-theme="secondary"] ,
a[data-theme="secondary"] {
  color: $use-color-secondary1;
}

p[data-theme="adapter"] ,
span[data-theme="adapter"] ,
a[data-theme="adapter"] {
  color: $use-color-adapter1;
}

p[data-theme="resource"] ,
span[data-theme="resource"] ,
a[data-theme="resource"] {
  color: $use-color-resource1;
}

p[data-theme="integration"] ,
span[data-theme="integration"] ,
a[data-theme="integration"] {
  color: $use-color-integration1;
}

.displaynone{display: none !important;}