@use "../../../assets/styles/utils.scss" as *;

#introText {
  color: $basic-white-color1;
  line-height: $use-line-height-normal;
  *:not(.btn) {
    color: inherit;
    line-height: inherit;
  }
  h2 {
    margin-top: 3rem;
    font-weight: $use-font-weight-bold;
    font-size: $use-font-size-headline2;
  }
  p {
    font-size: $use-font-size-headline6;
    font-weight: $use-font-weight-medium;
    margin : {
      top: 6rem;
    }
  }
  .btn {
    margin: {
      top: 2.5rem;
    }
  }
}
