@use "../../../assets/styles/utils.scss" as *;

#AlertPopup {
  display: none;
}
#AlertPopup.on{
  display: block !important;
}
.popup-layout-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.popup-layout {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $basic-white-color1;
  width: 60rem;
  border-radius: 1em;
  border: 1px solid $basic-gray-color3;
  padding: 1.2rem;


  &[theme="adapter"] .popup-header {
    background: $use-color-adapter2;
  }

  &[theme="resource"] .popup-header {
    background: $use-color-resource2;
  }

  &[theme="integration"] .popup-header {
    background: $use-color-integration2;
  }


  &[data-size="lg"] {
    width: 100rem;
  }

  .popup-layout-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .popup-header {
    width: 100%;
    padding: 1.8rem 1.5rem;
    background: $basic-blue-color1;
    border-radius: 0.5em;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;

    h6 {
      font-weight: $use-font-weight-medium;
    }
  }

  &[theme="adapter"] .popup-header {
    background: $use-color-adapter2;
  }

  &[theme="resource"] .popup-header {
    background: $use-color-resource2;
  }

  &[theme="integration"] .popup-header {
    background: $use-color-integration2;
  }


  .popup-body {
    margin: 2rem 0;
    flex-grow: 1;
    max-height: 50rem;
    overflow-y: auto;

    .view {
      padding: 1.5rem 2rem;

      * {
        line-height: $use-line-height-normal;
      }
    }

    li[class*="-item"] {
      padding-left: 1.8rem;
      padding-right: 1.8rem;
    }


    .detail-info-area {
      padding: 0;

      >li>strong {
        width: 100%;
      }

      li>span {
        width: 100%;

        >*:only-child {
          width: 100%;
        }
      }
    }

    .normal-list-area+.detail-info-area {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid $basic-gray-color2;
    }

    .detail-info-area+.detail-info-area {
      margin-top: 1.8rem;
    }

    input:not([type="radio"]):not([type="checkbox"]),
    select,
    .iv-data-view,
    .iv-data-option {
      height: 4.8rem;
      font-size: $use-font-size-body1;
    }

    textarea {
      min-height: 14rem;
      font-size: $use-font-size-body1;
    }


    .iv-input-area {
      width: 100%;

      .iv-base-label {
        max-width: 100%;
        width: 100%;
        *flex: 1 1 0;

        &.valid-chk {
          min-width: 100%;
        }
      }

      .btn {
        *min-width: 12rem;
      }
    }
  }

  .popup-footer {
    display: flex;
    padding: 2rem 0 1.5rem;
    justify-content: center;

    .btn-area {
      align-items: center;
      justify-content: center;
      width: 100%;

      .btn[data-type="text"] {
        min-width: 14rem;
      }

      .btn-next:only-child {
        display: block;
        margin-left: auto;
        margin-right: 1rem;
      }

      &:empty {
        display: none;
      }
    }
  }

  &.step {
    .popup-footer {
      border-top: 1px solid $basic-gray-color2;
    }

  }
}