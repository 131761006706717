@use "../../../assets/styles/utils.scss"as *;

.message-layout {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $basic-white-color1;
  min-width: 36rem;
  max-width: 100rem;
  border-radius: 1em;
  border: 1px solid $basic-gray-color3;
    padding: 0 0 3rem;


  &[data-size="lg"]{
    width: 50rem;
  }

  .message-layout-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .message-header {
    width: 100%;
    padding: 1.4rem 2.8rem;
    *background: $basic-blue-color1;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between; 
    border-radius: 0;
    border-bottom: 1px solid $basic-gray-color2;
    display: flex;
    align-items: center;

    p {
      color: $basic-gray-color4;
    }
  }




  .message-body {
    display: flex;
    align-items: center;
    min-height: 13rem;
    justify-content: center;
    flex-grow: 1;
    max-height: 50rem;
    overflow-y: auto;
    padding: 0 3rem;
    text-align: center;

    * {line-height: $use-line-height-normal; word-break: keep-all;}

    .view{
      padding: 1.5rem 2rem;
      *{line-height: $use-line-height-normal;}
    }

    li[class*="-item"] {
      padding-left: 1.8rem;
      padding-right: 1.8rem;
    }


    .detail-info-area {
      padding: 0;

      >li>strong {
        width: 100%;
      }

      li>span {
        width: 100%;

        >*:only-child {
          width: 100%;
        }
      }
    }

    .normal-list-area+.detail-info-area {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid $basic-gray-color2;
    }

    .detail-info-area+.detail-info-area {
      margin-top: 1.8rem;
    }

    input:not([type="radio"]):not([type="checkbox"]),
    select,
    .iv-data-view {
      height: 4.8rem;
    }

    textarea {
      min-height: 14rem;
    }


    .iv-input-area {
      width: 100%;

      .iv-base-label {
        max-width: 100%;
        width: 100%;
        *flex: 1 1 0;
        
        &.valid-chk{min-width: 100%;}
      }

      .btn {
        *min-width: 12rem;
      }
    }
  }

  .message-footer {
    display: flex;
    justify-content: center;
    padding-top:0.5rem;

    .btn-area {
      align-items: center;
      justify-content: center;
      width: 100%;
      .btn[data-type="text"] {
        min-width: 14rem;
      }
      .btn-next:only-child{
        display: block;
        margin-left: auto; margin-right: 1rem;
      }

      &:empty{display: none;}
    }
  }
}