@use "../../../assets/styles/utils.scss" as *;

input[type="text"],
input[type="password"],
input[type="search"] {
  width: 100%;
  padding: 1rem 2rem;
  border-color: $basic-gray-color2;
  border-radius: 0.4rem;
  cursor: text;
}

input[type="checkbox"] {
  width: 2rem;
  height: 2rem;
  position: relative;
  cursor: pointer;

  &:after {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px solid #333;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    @include check-img();
  }

  &:checked:after {
    background-color: $use-color-primary1;
    border-color: $use-color-primary1;
  }
}
