@use "../../../assets/styles/utils.scss" as *;

.btn-area {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(25% - 1rem), auto));
  gap: 1rem;
  grid-auto-flow: dense;
  align-content: stretch;

  > *:only-child {
    grid-column: 1/4;
  }
}
