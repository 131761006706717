@use "../../../assets/styles/utils.scss" as *;

.form {
  background: #fff;
  border-radius: 1.6rem;
  padding-top: 5rem;
  padding-bottom: 7rem;
  padding-left: 5rem;
  padding-right: 5rem;

  h3 {
    font-size: 3.6rem;
    color: #464646;
    text-align: center;
    font-weight: 700;
    margin: {
      bottom: 4rem;
    }
  }

  .inputs {
    display: grid;
    row-gap: 1.5rem;
  }

  .util {
    display: grid;
    margin: 3rem 0 4rem;
    grid-template-columns: 50% 50%;
    grid-auto-columns: 100%;
    align-items: center;
    justify-content: space-between;

    * {
      font-size: 1.4rem;
    }

    .input-width-label {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      column-gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      * {
        display: block;
      }
    }

    .list {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: max-content;
      justify-content: end;

      li {
        a {
          font-size: $use-font-size-caption1;
        }
        &:not(:last-of-type) {
          @include deco-between-list-items();
        }
      }
    }
  }

  .tip {
    margin-top: 3.2rem;
    font-size: 1.4rem;
    a {
      margin-left: 2rem;
      color: $use-color-primary1;
      @include deco-under-text-line($use-color-primary1);
    }
  }
}
