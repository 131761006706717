@use "../../../assets/styles/utils.scss" as *;

#footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: $basic-size10;
    color: $use-color-background1;
  }

  .contact {
    display: flex;
    align-items: center;
    p {
      margin-left: 2.2rem;
    }
  }
}
