@use "../../../assets/styles/utils.scss" as *;

.layout-intro {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0 5%;
  overflow-y: hidden;
  height: 100%;
  background-size: cover;

  #contents {
    @include wide-inner;
    display: grid;
    grid-template-columns: auto min(45%, 60rem);
    column-gap: min(5%, 13.5rem);
    align-content: center;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;

    #introText {
      place-self: start;
    }
  }

  #footer {
    @include wide-inner;
    flex-shrink: 0;
  }
}
