@use "../../../assets/styles/utils.scss"as *;

.btn-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.btn {
  $type: text;
  $bg: $use-color-primary1;
  $border-color: $use-color-primary1;
  $color: $use-color-background1;
  $height: $basic-size2;
  $icon-size: 0;
  $font-size: $use-font-size-body1;
  $padding1: 0 calc(#{$icon-size} + #{$icon-size}/ 2) 0 calc(#{$icon-size} + #{$icon-size}/ 2);

  display: inline-grid;
  position: relative;
  column-gap: 1rem;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  justify-content: center;
  align-items: center;
  background: $bg;
  border-color: $border-color;
  color: $color;
  padding: $padding1;
  height: $height;
  border-radius: 5em;
  font-size: $font-size;
  text-align: center;
  border: 1px solid $border-color;

  &:not(.btn-disabled) {
    &:hover {
      opacity: 0.8;
    }
  }
  svg,
  img {
    max-width: $icon-size;
    max-height: $icon-size;
    position: absolute;
    left: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &[href] {
    padding-right: calc(#{$icon-size} + #{$icon-size});

    &::after {
      display: block;
      position: absolute;
      width: 0.5em;
      height: 0.5em;
      border-right: 1px solid $border-color;
      border-top: 1px solid $border-color;
      transform: rotate(45deg);
      content: "";
    }

    &[data-size="lg"] {

      &::after {
        border-width: 2px;
      }
    }
  }

  /* size */
  &[data-size="sm"] {
    height: $basic-size4;
    $icon-size: $basic-size7;
    font-size: $use-font-size-button1;
    padding: 0 1.3rem;

    &[data-type*="text"][data-type*="icon"] {
      $padding1: 0 calc(#{$icon-size}*0.8) 0 calc(#{$icon-size} + #{$icon-size}/ 2);
      padding: $padding1;
    }

    svg,
    img {
      max-width: $icon-size;
      max-height: $icon-size;
    }

    &::after {
      right: calc(#{$icon-size}* 0.7);
    }

    &[href] {
      padding-right: calc(#{$icon-size} + #{$icon-size});
    }
  }

  &[data-size="md"] {
    height: $basic-size2;
    $icon-size: $basic-size5;
    padding: 0 1.5rem;

    &[data-type*="text"][data-type*="icon"] {
      $padding1: 0 calc(#{$icon-size}*0.8) 0 calc(#{$icon-size} + #{$icon-size}/ 2);
      padding: $padding1;
    }

    svg,
    img {
      max-width: $icon-size;
      max-height: $icon-size;
    }

    &::after {
      right: calc(#{$icon-size}* 0.7);
    }

    &[href] {
      padding-right: calc(#{$icon-size} + #{$icon-size});
    }
  }

  &[data-size="lg"] {
    height: $basic-size1;
    $icon-size: $basic-size4;
    font-size: $use-font-size-headline6;
    padding: 0 2rem;

    &[data-type*="text"][data-type*="icon"] {
      $padding1: 0 calc(#{$icon-size}*0.8) 0 calc(#{$icon-size} + #{$icon-size}/ 2);
      padding: $padding1;
    }

    svg,
    img {
      max-width: $icon-size;
      max-height: $icon-size;
    }

    &::after {
      right: calc(#{$icon-size}* 0.7);
    }

    &[href] {
      padding-right: calc(#{$icon-size} + #{$icon-size});
    }
  }

  &[data-size="lg2"] {
    height: $basic-size1;
    $icon-size: $basic-size4;
    font-size: $use-font-size-headline7;
    padding: 0 5rem;

    &[data-type*="text"][data-type*="icon"] {
      $padding1: 0 calc(#{$icon-size}*0.8) 0 calc(#{$icon-size} + #{$icon-size}/ 2);
      padding: $padding1;
    }

    svg,
    img {
      max-width: $icon-size;
      max-height: $icon-size;
    }

    &::after {
      right: calc(#{$icon-size}* 0.7);
    }

    &[href] {
      padding-right: 6.9rem;
    }
  }

  /* bg */
  &[data-bg="primary"] {
    background-color: $use-color-primary1;
    color: $use-color-background1;
    border-color: $use-color-primary1;

    &::after {
      border-color: $use-color-background1;
    }
  }

  &[data-bg="secondary"] {
    background-color: $use-color-secondary1;
    color: $use-color-background1;
    border-color: $use-color-secondary1;

    &::after {
      border-color: $use-color-background1;
    }
  }

  &[data-bg="success"] {}

  &[data-bg="danger"] {}

  &[data-bg="warning"] {
    background-color: $use-color-error1;
    border-color: $use-color-error1;
  }

  &[data-bg="info"] {
    background-color: $basic-gray-color3;
    color: $use-color-background1;
    border-color: $basic-gray-color3;
  }

  &[data-bg="light"] {
    background-color: $use-color-background1;
    color: $use-color-primary1;
    border-color: $use-color-background1;

    &::after {
      border-color: $use-color-primary1;
    }
  }

  &[data-bg="dark"] {
    background-color: $basic-black-color2;
    color: $basic-white-color1;
    border-color: $basic-black-color2;

    &::after {
      border-color: $basic-white-color1;
    }
  }

  &[data-bg="lightgray"] {
    background-color: $basic-gray-color3;
    color: $basic-white-color1;
    border-color: $basic-gray-color3;

    &::after {
      border-color: $basic-white-color1;
    }
  }

  &[data-bg="darkgray"] {
    background-color: $basic-gray-color4;
    color: $basic-white-color1;
    border-color: $basic-gray-color4;

    &::after {
      border-color: $basic-white-color1;
    }
  }

  &[data-bg="adapter"] {
    background-color: $use-color-adapter1;
    border-color: $use-color-adapter1;
    color: $basic-white-color1;

    &::after {
      border-color: $basic-white-color1;
    }
  }


  &[data-bg="integration"] {
    background-color: $use-color-integration1;
    border-color: $use-color-integration1;
    color: $basic-white-color1;

    &::after {
      border-color: $basic-white-color1;
    }
  }


  &[data-bg="resource"] {
    background-color: $use-color-resource1;
    border-color: $use-color-resource1;
    color: $basic-white-color1;

    &::after {
      border-color: $basic-white-color1;
    }

    svg,
    svg path {
      fill: $basic-white-color1;
    }
  }

  &[data-bg="transparent"] {
    background: transparent;
  }

  &[data-bg="ftp"] {
    background-color: $use-color-ftp;
    border-color: $use-color-ftp;
    color: $basic-white-color1;

    &::after {
      border-color: $basic-white-color1;
    }

    svg,
    svg path {
      fill: $basic-white-color1;
    }
  }

  &[data-bg="sftp"] {
    background-color: $use-color-sftp;
    border-color: $use-color-sftp;
    color: $basic-white-color1;

    &::after {
      border-color: $basic-white-color1;
    }

    svg,
    svg path {
      fill: $basic-white-color1;
    }
  }

  &[data-bg="gray4"] {
    background-color: $basic-gray-color4;
    border-color: $basic-gray-color4;
    color: $basic-white-color1;

    &::after {
      border-color: $basic-white-color1;
    }

    svg,
    svg path {
      fill: $basic-white-color1;
    }
  }

  /* outline */
  &[data-outline="primary"] {
    background-color: $use-color-background1;
    color: $use-color-primary1;
    border-color: $use-color-primary1;
  }

  &[data-outline="secondary"] {
    background-color: $use-color-background1;
    color: $use-color-secondary1;
    border-color: $use-color-secondary1;
  }

  &[data-outline="success"] {}

  &[data-outline="danger"] {}

  &[data-outline="warning"] {}

  &[data-outline="info"] {}

  &[data-outline="light"] {}

  &[data-outline="dark"] {}

  &[data-outline="adapter"] {
    background-color: $basic-white-color1;
    border-color: $use-color-adapter1;
    color: $use-color-adapter1;

    &::after {
      border-color: $use-color-adapter1;
    }
  }

  &[data-outline="resource"] {
    background-color: $basic-white-color1;
    border-color: $use-color-resource1;
    color: $use-color-resource1;

    &::after {
      border-color: $use-color-resource1;
    }
  }


  &[data-outline="integration"] {
    background-color: $basic-white-color1;
    border-color: $use-color-integration1;
    color: $use-color-integration1;

    &::after {
      border-color: $use-color-integration1;
    }
  }

  &[data-outline="transparent"] {
    border-color: transparent;
  }

  &[data-type="icon"] {
    border-radius: $use-border-radius-circle;
    padding: 0 !important;

    &[href]::after {
      display: none !important;
    }

    &[data-size="sm"] {
      width: $basic-size8;
      height: $basic-size8;
      font-size: $basic-size8;
      padding: 0.2rem;
    }

    &[data-size="md"] {
      width: $basic-size6;
      height: $basic-size6;
      font-size: $basic-size6;
    }

    &[data-size="lg"] {
      width: $basic-size5;
      height: $basic-size5;
      font-size: $basic-size5;
    }

    &[data-size="xl"] {
      width: $basic-size3;
      height: $basic-size3;
      font-size: $basic-size3;
    }

    svg,
    img {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.btn[data-type="icon"] .arrow-icon[data-direction="left"] {
  transform: rotate(180deg);
}

.btn[data-type*="text"][data-type*="icon"] {

  &[data-outline="adapter"] {
    svg {
      >path {
        fill: $basic-white-color1;
      }
    }

    span:not(first-child)::before {
      background-color: $use-color-adapter1;
    }
  }
}



.btn[data-type*="text"][data-type*="icon"] svg {
  font-size: inherit;
  height: auto;
  min-height: 0.8em;
  width: 2em;
  position: relative;
  z-index: 1;
  position: absolute;
  left: calc(0.5rem + 1.5rem);
  top: 50%;
  transform: translate(-50%, -50%) scale(1.5);

  >path {
    width: 100%;
    height: 100%;
  }
}

.btn[data-type*="text"][data-type*="icon"][data-size="sm"] svg {
  left: calc(0.5rem + 1rem);
}

.btn[data-type*="text"][data-type*="icon"][data-size="lg"] svg {
  left: 2.4rem;
  width: 1.3em;
}

.btn[data-type*="text"][data-type*="icon"] span:not(first-child)::before {
  background: #fff;
  font-size: inherit;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  display: block;
  content: "";
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.btn[data-type*="text"][data-type*="icon"][data-size="sm"]span:not(first-child)::before {
  width: 1.8em;
  height: 1.8em;
  left: 0.3rem;
}

.btn[data-type*="text"][data-type*="icon"] span:not(first-child) {
  padding-left: 1em;
}

.btn[data-type*="text"][data-type*="icon"][data-size="sm"] span:not(first-child) {
  padding-left: 0.5em;
}

.btn[data-fill="primary1"] {
  svg path {
    fill: $use-color-primary1;
  }
}

.btn[data-fill="primary2"] {
  svg path {
    fill: $use-color-primary2;
  }
}

.btn[data-fill="secondary1"] {
  svg path {
    fill: $use-color-secondary1;
  }
}

.btn[data-fill="secondary2"] {
  svg path {
    fill: $use-color-secondary2;
  }
}

.btn[data-fill="adapter1"] {
  svg path {
    fill: $use-color-adapter1;
  }
}

.btn[data-fill="adapter2"] {
  svg path {
    fill: $use-color-adapter2;
  }
}

.btn[data-fill="adapter1"] {
  svg path {
    fill: $use-color-adapter1;
  }
}

.btn[data-fill="adapter1"] {
  svg path {
    fill: $use-color-adapter1;
  }
}


.btn[data-fill="black1"] {
  svg path {
    fill: $basic-black-color1;
  }
}

.btn[data-fill="black2"] {
  svg path {
    fill: $basic-black-color2;
  }
}

.btn[data-fill="gray1"] {
  svg path {
    fill: $basic-gray-color1;
  }
}

.btn[data-fill="gray2"] {
  svg path {
    fill: $basic-gray-color2;
  }
}

.btn[data-fill="gray3"] {
  svg path {
    fill: $basic-gray-color3;
  }
}

.btn[data-fill="gray4"] {
  svg path {
    fill: $basic-gray-color4;
  }
}

.btn[data-fill="white1"] {
  svg path {
    fill: $basic-white-color1;
  }
}

.btn[data-type="list"] {
  margin-right: 1rem;

  &[data-size="sm"] {
    width: $basic-size8;
    height: $basic-size8;
    font-size: $basic-size8;
    padding: 0;
  }

  &[data-size="md"] {
    width: $basic-size6;
    height: $basic-size6;
    font-size: $basic-size6;
    padding: 0;
  }

  &[data-size="lg"] {
    width: $basic-size5;
    height: $basic-size5;
    font-size: $basic-size5;
    padding: 0;
  }

  &[data-size="xl"] {
    width: $basic-size3;
    height: $basic-size3;
    font-size: $basic-size3;
    padding: 0;
  }
}

.btn[data-disabled] {
  background-color: $basic-gray-color3;
  border-color: $basic-gray-color3;
}

.btn.push-button {
  display: block;
  height: $basic-size7;
  width: $basic-size4 !important;
  padding: 0;

  &::before {
    display: block;
    content: "";

    border-radius: 50%;
    background: $basic-white-color1;
    position: absolute;
    left: 0.2rem;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s ease-in-out;

    height: calc(#{$basic-size7} - 0.5rem);
    width: calc(#{$basic-size7} - 0.5rem);
  }

  /*
  &[data-size="md"] {
    &::before {
      height: calc(#{$basic-size2} - 0.5rem);
      width: calc(#{$basic-size2} - 0.5rem);
    }
  }

  &[data-size="sm"] {
    &::before {
      height: calc(#{$basic-size4} - 0.5rem);
      width: calc(#{$basic-size4} - 0.5rem);
    }
  }
  */

  &:not(.on) {
    background: $basic-gray-color3;
    border-color: $basic-gray-color3;

    &::before {
      left: calc(100% - 0.2rem);
      transform: translate(-100%, -50%);
    }
  }
}