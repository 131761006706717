@use "../../../assets/styles/utils.scss" as *;

.tab-area {
    .tab-list {
        display: inline-grid;
        grid-auto-flow: column;
        align-items: flex-end;
        padding-left: 1em;

        .tab-item {
            color: $basic-gray-color4;
            font-weight: $use-font-weight-medium;
            background: $basic-gray-color3;
            width: 8em;
            line-height: 3.5rem;
            text-align: center;
            vertical-align: middle;
            border-top-left-radius: $use-border-radius-xs;
            border-top-right-radius: $use-border-radius-xs;
            &:hover {
                cursor: pointer;
            }

            &.on {
                color: $basic-black-color2;
                font-weight: $use-font-weight-regular;
                background: white;

                // &::before {
                //     display: block;
                //     content:"";
                //     width: 100%; 
                //     height: 4px;
                //     background: $basic-gray-color4;
                //     margin-bottom: 0.5em;
                //     border-radius: $use-border-radius-xs;

                // }
            }
        }
    }



    &[data-align="vertical"] {
        width: 16rem;
        padding: 2rem 0 1.5rem 2rem;
        border-radius: $use-border-radius-xs;
        height: 100%;

        .tab-list {
            width: 100%;
            grid-auto-flow: row;
            padding: 0;
            row-gap: 2rem;

            .tab-item {
                display: flex;
                align-items: center;
                color: $basic-gray-color4;
                font-weight: $use-font-weight-regular;
                padding-right: 2.5rem;
                position: relative;

                a {
                    font-weight: inherit;
                }

                &.on {
                    color: $basic-black-color2;
                    font-weight: $use-font-weight-medium;

                    a {
                        text-decoration: underline;
                    }


                    &::before {
                        display: block;
                        position: absolute;
                        right: 1.5rem;
                        top: 0.5rem;
                        content: "";
                        width: 0.9rem;
                        height: 0.9rem;
                        background: none;
                        border-right: 1px solid $basic-black-color2;
                        border-top: 1px solid $basic-black-color2;
                        transform: rotate(45deg);
                        margin-bottom: 0;
                        border-radius: 0
                    }
                }
            }
        }
    }
}