@use "../../../assets/styles/utils.scss" as *;

.board-detail-layout {
  padding: 2.5rem 2rem;

  .detail-header {
    h6 {
      padding: 0 2rem 2.5rem;
      margin: 0 0 2.5rem;
      border-bottom: 1px solid $basic-black-color2;
      &:empty {
        display: none;
      }
    }
  }

  .table-wrapper {
    margin-top: 2.5rem;
  }
}

.toggle-table-wrapper {
  table {
    table-layout: fixed;
  }

  tr {
    &:not(.on) {
      .btn {
        background: $basic-gray-color4;
        border-color: $basic-gray-color4;
      }
      +tr.toggle-contents-tr:nth-child(2n) {
        display: none;
      }
    }

    &.on {
      .btn {
        transform: rotate(180deg);
      }
    }
  }

  .toggle-contents-tr {
    background: $basic-gray-color1;

    > td {
      padding: 3rem 3.5rem 3rem 8.5rem !important;
      table {
        tr {
          &:not(:last-child) {
            border-bottom: 1px dotted $basic-gray-color3;
          }
        }
        th,
        td {
          padding: 1.5rem 0;
          font-size: $use-font-size-button1;
        }
        th {
          width: 16rem;
        }
        td {
          width: calc(100% - 16rem);
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }

  th:first-child,
  td:first-child {
    width: 10rem;
  }
}

.detail-info-area {
  //padding: 0 1.8rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  //+ .detail-info-area {
  //  margin-top: 4rem;
  //}
  > ul {
    display: flex;
    flex: 1 1 0;

    &:not(:last-child) {
      *margin-bottom: 4rem;
    }
    > li {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      margin: 0 1rem;
      justify-content: flex-end;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      &.btn-area {
        flex: none;
        flex-direction: row;
        align-items: center;
        height: 3rem;
        align-self: flex-end;
        > * {
          margin: 0;
          &:first-child {
            margin-right: 1.2rem;
          }
        }
      }

      > strong {
        display: flex;
        align-items: center;
        margin-bottom: 0.7rem;
        font-size: $use-font-size-button1;
        color: $basic-black-color2;
        font-weight: $use-font-weight-regular;

        > .btn.push-button {
          margin-left: 1.7rem;
        }

        &::before {
          display: inline-block;
          width: 0.8rem;
          height: 0.8rem;
          margin-top: 0.3rem;
          background: $basic-black-color2;
          border-radius: 50%;
          margin-right: 0.3em;
          content: "";
        }
      }

      > span[data-align="vertical"] {
        display: block;
        color: $basic-gray-color4;
        font-size: $use-font-size-button1;
        line-height: 3rem;
        align-items: center;
        > .iv-base-label {
          margin-right: 1.5rem;
        }
      }

      > span {
        display: inline-flex;
        justify-content: flex-start !important;
        color: $basic-gray-color4;
        font-size: $use-font-size-button1;
        line-height: 3rem;
        align-items: center;
        > .iv-base-label {
          margin-right: 1.5rem;
        }
        .sub-text-box {
          display: flex;
          align-items: center;
          color: $basic-black-color1;
          margin-left: auto;
          margin-right: 0;

          > .btn.push-button {
            margin-left: 1.7rem;
          }
        }
      }

      input:not([type="radio"]):not([type="checkbox"]),
      select {
        width: auto;
        flex-grow: 1;
        height: 3rem;
        min-width: 20rem;
        max-width: 100%;
      }
    }
  }
}

.detail-path-table {
  // display: flex;
  align-items: stretch;

  > .detail-path-area {
    width: 100%;

    .detail-path-list {
      .detail-path-link {
        display: flex;
        span {
          margin-left: 1.5rem;
        }
      }
      .detail-path-item {
        padding: 1.8rem 0;
      }

      .detail-path-list {
        padding: 0.5rem 0;
        margin-left: 3rem;
      }

      .btn {
        border-radius: 0.4rem;
      }
    }
  }
  > .table-wrapper {
    // width: calc(100% - 20rem);
    width: 100%;
  }
}

.table-active-chk {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn {
    margin-right: 1rem;
  }
}

.table-bottom-area {
  text-align: center;
  padding: 3rem 0 0;
  margin-top: 4rem;
  border-top: 1px solid $basic-gray-color4;
  .btn-area {
    margin: 0 auto;
  }
}

.normal-list + .detail-info-area {
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid $basic-gray-color4;
}

.append-form-wrapper {
  display: block;
  width: 100%;
}

.simple-table-wrapper {
  width: 100%;
  thead tr {
    border-bottom: 1px solid $use-color-primary1;
  }
  thead th {
    color: $use-color-primary1;
  }
  thead th:not(.center){
    text-align: start;
  }


  tbody tr {
    border-bottom: 1px solid $use-color-primary1;
  }

  .icon-th,
  .icon-td {
    width: 5rem;
  }

  th,
  td {
    padding: 1.5rem;
    font-size: $use-font-size-button1;
  }
}

.append-form {
  display: flex;
  margin: 2.5rem 0 0;
  align-items: center;
  > *:not(.btn-area) {
    flex: 1 1 0;
    margin-right: 2rem;
  }
  .btn-area {
    width: 5rem;
    transform: translateY(1.2rem);
  }
  .iv-base-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      margin: 0 0 1rem;
    }
    input {
      width: 100% !important;
    }
  }
}